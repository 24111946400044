<script>
  import { _ } from '@/libs/i18n'

  export let correct = 0,
    wrong = 0
</script>
<div class='progress-indicators'>
  <span class='indicator -correct _row'> <span class='pill'>{correct}</span> {$_('testsPage.labels.correct')}</span>
  <span class='indicator -wrong _row'> <span class='pill'>{wrong}</span> {$_('testsPage.labels.wrong')}</span>
</div>
<style lang='scss'>
  .progress-indicators {
    display: flex;
    gap: 2.4rem;
    font: var(--Medium-600);

    > .indicator {
      > .pill {
        min-width: 3.6rem;
        padding: 0.4rem 0.8rem;
        text-align: center;
        border-radius: 0.4rem;
      }

      &.-correct {
        color: var(--success-text);

        > .pill {
          background-color: var(--success-background);
        }
      }

      &.-wrong {
        color: var(--error-text);

        > .pill {
          background-color: var(--error-background);
        }
      }
    }
  }

  @media (max-width: 768px) {
    .progress-indicators {
      font: var(--Medium-500);
    }
  }
</style>
