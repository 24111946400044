<script lang='ts'>
  import { link } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { updatePoints } from '@/helpers/game'
  import { _ } from '@/helpers/i18n'
  import { disableElement, disableInputs } from '@/helpers/mixHelpers'

  import RadioQuestions from '@/components/test/RadioQuestions.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'

  import { TestQuestion } from '@/definitions/langoid'

  const perPage = 100
  const dontKnowString = 'I DØN\'T KNǒW!'

  const elements: number[] = []
  let questions: TestQuestion[] = [],
    loaded = false,
    sum = 1,
    all = 1,
    passedQuestions = 0,
    answered = false,
    currentQuestion = 0,
    offset = 0,
    solved = {
      correct: 0,
      wrong: 0
    }

  $: passedQuestions = 1 + currentQuestion + offset

  loadPage()

  async function loadPage () {
    const data = await fetchData('test/vcPage', {})
    questions = data.questions || []
    loaded = true
    all = data.numOfQuestions
    solved = {
      correct: data.solved?.correct || 0,
      wrong: data.solved?.wrong || 0
    }
    sum = perPage
    offset = sum - questions.length
  }

  const checkSolution = async (markAsIDontKnow = false) => {
    const id = questions[currentQuestion].id
    questions[currentQuestion].answered = true
    const answer = markAsIDontKnow ? dontKnowString : (elements[currentQuestion] || '')
    const data = await fetchData('test/vcCheck', { answer, id })
    if (data.correct) {
      solved.correct++
    } else {
      solved.wrong++
    }
    updatePoints(data.points, data.level)
    const form: HTMLElement | null = document.getElementById('question-' + currentQuestion)
    answered = true
    if (form) {
      disableInputs(form, 'input[type="radio"]')
      disableElement(form, 'input[type="submit"]')
    }
    nextQuestion()
    answered = false
  }

  function handleKeydown (e: KeyboardEvent) {
    if (questions.length === 0) {
      return
    }
    const keyPressed = e.key
    const type = questions[currentQuestion]?.type || 0
    if (type === 0) {
      return
    }
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyPressed)) {
      const selector = `input[name="answer_${questions[currentQuestion].id}"]`
      const els = Array.from(document.querySelectorAll(selector))
      const el = els[parseInt(keyPressed) - 1] as HTMLInputElement
      if (el) {
        el.click()
        el.checked = true
      }
      e.preventDefault()
    }
  }

  function handleKeyPress (e: KeyboardEvent) {
    if (e.key === 'Enter') {
      const targetEvent = e.target as HTMLElement
      if (targetEvent.tagName === 'BUTTON') {
        return
      }
      const isAnswered = questions[currentQuestion].answered
      if (isAnswered && passedQuestions <= sum) {
        nextQuestion()
      }
    }
  }

  function nextQuestion () {
    if (sum <= passedQuestions) { // todo - check this
      questions = []
      currentQuestion = 0
      answered = false
      loadPage()
    } else {
      currentQuestion = currentQuestion + 1
      answered = false
    }
  }

  function iDontKnow () {
    checkSolution(true)
  }
</script>

<svelte:window on:keydown={handleKeydown} on:keypress={handleKeyPress} />
<div class='_gap24'>
  {#if loaded}
    <ProgressBar clickable={false} maxProgress={all} progress={solved.correct + solved.wrong} showPercents />

    {#each questions as question, id}
      <form
        id='question-{id}'
        class='question-form'
        class:-active={currentQuestion === id}
        on:submit|preventDefault={() => checkSolution()}
      >
        <div class='question-block'>
          <div class='info'>{$_('testsPage.chooseTranslation')}</div>
          <div class='questionMain'>
            <div class='word'>{question.word}</div>
            {#if question.pos}
              <div class='pos'>{$_('pos.' + question.pos)}</div>
            {/if}
          </div>
          <RadioQuestions {id} {checkSolution} {elements} {question} />
          <div class='test-additional-controls'>
            <button
              class='control -iDontKnow'
              type='button'
              on:click={iDontKnow}
            >{$_('testsPage.iDontKnow')}</button>
            {#if answered}
              <button
                class='control'
                type='button'
                on:click={nextQuestion}
              >
                {passedQuestions < sum ? $_('testsPage.next') : $_('finish')}
              </button>
            {/if}
          </div>
        </div>
      </form>
    {/each}
    {#if all === (solved.correct + solved.wrong) && all > 0}
      <div class='finished-vc'>
        All words checked. We will not ask you words you showed that you know in this test, but rest of words will be asked again in word tests. <br />
        <a href='/' use:link>Back to learning</a>
      </div>
    {/if}
    <div class='solving-text'>
      {$_('testsPage.xOfYWordsChecked', { values: { all, sum: solved.correct + solved.wrong } })}
    </div>
    <div class='solving-text'>{$_('testsPage.knowToLearnTocheck', {
      values: {
        know: solved.correct,
        toCheck: all - (solved.correct + solved.wrong),
        toLearn: solved.wrong
      }
    })}</div>

    <br />
  {:else}
    {$_('loading')}
  {/if}
</div>
<style lang='scss'>
  .solving-text {
    margin-top: 0.8rem;
    padding: 0.8rem 0;
    text-align: center;
    border-top: 0.1rem solid var(--Primary-Lighter);
    border-bottom: 0.1rem solid var(--Primary-Lighter);
  }

  .finished-vc {
    font-size: 2em;
  }
</style>
