<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import { updatePoints } from '@/helpers/game'
  import { _ } from '@/helpers/i18n'
  import { disableElement, disableInputs } from '@/helpers/mixHelpers'

  import RadioQuestions from '@/components/test/RadioQuestions.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'

  import { QualificationApiData, QualificationBatches, TestQuestion } from '@/definitions/langoid'

  const dontKnowString = 'I DØN\'T KNǒW!'
  const elements: number[] = []
  let inProgress = false,
    allowed = true,
    questions: TestQuestion[] = [],
    batches: QualificationBatches[] = [],
    loaded = false,
    sum = 1,
    all = 1,
    passedQuestions = 0,
    firstBatch: QualificationBatches = {} as QualificationBatches,
    answered = false,
    currentQuestion = 0,
    offset = 0

  $: passedQuestions = 1 + currentQuestion + offset

  loadPage()

  async function loadPage () {
    const data: QualificationApiData = await fetchData('test/qualificationTestPage', {})
    batches = data.batches
    firstBatch = data.batches?.[0] || {} as QualificationBatches
    questions = data.questions || []
    inProgress = data.inProgress
    allowed = data.allowed
    loaded = true
    all = firstBatch.num_of_questions || 0
    sum = questions.length || 0
    offset = sum === 0 ? 0 : all - sum
  }

  const startTest = async () => {
    const data = await fetchData('test/qualificationTestStart', {})
    questions = data || []
    inProgress = true
    sum = questions.length
    all = questions.length
  }

  const checkSolution = async (markAsIDontKnow = false) => {
    const id = questions[currentQuestion].id
    questions[currentQuestion].answered = true
    const answer = markAsIDontKnow ? dontKnowString : (elements[currentQuestion] || '')
    const data = await fetchData('test/qualificationTestCheck', { answer, id })
    updatePoints(data.points, data.level)
    const form: HTMLElement | null = document.getElementById('question-' + currentQuestion)
    answered = true
    if (form) {
      disableInputs(form, 'input[type="radio"]')
      disableElement(form, 'input[type="submit"]')
    }

    nextQuestion()
    answered = false
  }

  function handleKeydown (e: KeyboardEvent) {
    if (questions.length === 0) {
      return
    }
    const keyPressed = e.key
    const type = questions[currentQuestion]?.type || 0
    if (type === 0) {
      return
    }
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyPressed)) {
      const selector = `input[name="answer_${questions[currentQuestion].id}"]`
      const els = Array.from(document.querySelectorAll(selector))
      const el = els[parseInt(keyPressed) - 1] as HTMLInputElement
      if (el) {
        el.click()
        el.checked = true
      }
      e.preventDefault()
    }
  }

  function handleKeyPress (e: KeyboardEvent) {
    if (e.key === 'Enter') {
      const targetEvent = e.target as HTMLElement
      if (targetEvent.tagName === 'BUTTON') {
        return
      }
      const isAnswered = questions[currentQuestion].answered
      if (isAnswered && passedQuestions <= all) {
        nextQuestion()
      }
    }
  }

  function nextQuestion () {
    if (all === passedQuestions) {
      loadPage()
    } else {
      currentQuestion = currentQuestion + 1
      answered = false
    }
  }

  function iDontKnow () {
    checkSolution(true)
  }
</script>

<svelte:window on:keydown={handleKeydown} on:keypress={handleKeyPress} />
<div class='_gap24'>
  {#if loaded}
    {#if inProgress}
      <ProgressBar clickable={false} maxProgress={all} progress={passedQuestions || 1} showNumbers />

      {#each questions as question, id}
        <form
          id='question-{id}'
          class='question-form'
          class:-active={currentQuestion === id}
          on:submit|preventDefault={() => checkSolution()}
        >
          <div class='question-block'>
            <div class='info'>{$_('testsPage.chooseTranslation')}</div>
            <div class='questionMain'>
              <div class='word'>{question.word}</div>
              {#if question.pos}
                <div class='pos'>{$_('pos.' + question.pos)}</div>
              {/if}
            </div>
            <RadioQuestions {id} {checkSolution} {elements} {question} />
            <div class='test-additional-controls'>
              <button
                class='control -iDontKnow'
                type='button'
                on:click={iDontKnow}
              >{$_('testsPage.iDontKnow')}</button>
              {#if answered} <!-- todo - unnecessary if block? -->
                <button
                  class='control'
                  type='button'
                  on:click={nextQuestion}
                >
                  {passedQuestions < all ? $_('testsPage.next') : $_('finish')}
                </button>
              {/if}
            </div>
          </div>
        </form>
      {/each}
    {:else}
      <div class='qualification-page'>
        {#if allowed}
          <h2>{$_('qTests.title')}</h2>
          <div class='reminderQt'>
            <p class='introDuction'>{$_('qTests.intro')}</p>
            <p class='purpose'>{$_('qTests.purpose')}</p>
            <p>{$_('qTests.timeLimitMessage', { values: { timeLimit: 60 } })}</p>
            <p>{$_('qTests.audioWarning')}</p>
          </div>
          <button
            class='startTest'
            type='button'
            on:click={startTest}
          >{$_('qTests.startTest')}</button>
        {/if}
      </div>
      <!-- todo - add check if suggested level is bigger than maximum we can offer,
              that should be done also in calculations on the server.
              Otherwise if we have just words from A1 someone will get C2 as suggested
              even if he knows just A1 -->
      <div class='test-result'>
        {#if firstBatch.id}
          <h4>{$_('qTests.lastResult')}:</h4>
          <p>{$_('qTests.suggestLevel')}:
            <span class='suggested-level -{firstBatch.suggested_level.toLowerCase()}'>{firstBatch.suggested_level}</span>
          </p>
          {#if ['A2', 'B1', 'B2', 'C1'].includes(firstBatch.suggested_level)}
            <p>{$_('qTests.vc1')}</p>
            <p>{$_('qTests.vc2')}</p>
          {/if}
          {#if firstBatch.suggested_level === 'A1'}
            <p>{$_('qTests.stillA1')}</p>
          {/if}
          {#if firstBatch.suggested_level === 'C2'}
            <p>{$_('qTests.tooGood')}</p>
          {/if}
        {/if}
        {#if batches.length}
          <p class='introResult'>{$_('qTests.intro')}</p>
          <h2>{$_('qTests.prevQTests')}</h2>
          <table class='_table'>
            <tr>
              <th>{$_('points')}</th>
              <th>{$_('qTests.suggestLevel')}</th>
              <th>{$_('qTests.createdAt')}</th>
              <th>{$_('qTests.timeEnd')}</th>
            </tr>
            {#each batches as batch}
              <tr>
                <td>{batch.points} / {batch.num_of_questions}</td>
                <td>{batch.suggested_level}</td>
                <td>{batch.created_at}</td>
                <td>{batch.time_end}</td>
              </tr>
            {/each}
          </table>
        {/if}
      </div>
    {/if}
  {:else}
    {$_('loading')}
  {/if}
</div>

<style lang='scss'>
  .qualification-page {
    max-width: 68.4rem;
    margin: 0.1rem auto;

    > .reminderQt {
      margin-bottom: 2.4rem;
      padding: 0.8rem 2.4rem;
      font-variant: small-caps;
      background: var(--Gray-Lighter);
      border: 0.1rem solid var(--Primary-Lighter);

      > .purpose {
        display: inline;
        clear: both;
        background: var(--Error-Lighter);
      }
    }

    > .startTest {
      float: right;
    }
  }

  .test-result {
    clear: both;
    max-width: 120rem;
    margin: 0.1rem auto;
    font-weight: bold;

    > .introResult {
      font-style: italic;
      font-variant: small-caps;
    }
  }

  .suggested-level {
    padding: 0 1rem;
    font-size: 3rem;
    background: transparent;
    border-radius: 1rem;

    &.-a1 {
      background: var(--a1);
    }

    &.-a2 {
      background: var(--a2);
    }

    &.-b1 {
      background: var(--b1);
    }

    &.-b2 {
      background: var(--b2);
    }

    &.-c1 {
      background: var(--c1);
    }
  }
</style>
